import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, EffectFade, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import styles from "./styles/App.module.css";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default function App() {
  const [photos, setPhotos] = useState([]);
  const [numSlides, setNumSlides] = useState(1);
  const size = useWindowSize();

  // not sure why this doesn't work in regular css
  const centerPulser = {
    display: "flex",
    justifyContent: "center",
  };

  const Swipers = () => (
    <Swiper
      modules={[Navigation, EffectFade, Pagination]}
      pagination={{ dynamicBullets: true }}
      effect={"slide"}
      slidesPerView={numSlides}
      speed={400}
      spaceBetween={50}
      loop
      className={styles.swiperWrapper}
    >
      {photos.length === 0 ? (
        <div style={centerPulser}>
          <img src={require("./img/pulser.gif")} alt="loading..." />
        </div>
      ) : (
        photos.map((photo) => (
          <SwiperSlide key={photo.id} className={styles.swiperSlide}>
            <img className="image" src={photo.baseUrl} alt="" />
          </SwiperSlide>
        ))
      )}
    </Swiper>
  );

  let fetchPhotos = async () => {
    const _images = [];
    let response = await fetch(
      "https://trbsjfzihf.execute-api.us-east-1.amazonaws.com/v1/getphotos"
    );
    const data = await response.json();
    data.media.mediaItems.forEach((item) => {
      _images.push(item);
    });
    setPhotos(_images);
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  useEffect(() => {
    // handle issue where size is NaN
    let s = parseInt(size.width / 350);
    if (s) {
      setNumSlides(s);
    }
  }, [size]);

  return (
    <div className={styles.swiperContainer}>
      <Swipers />
      <a
        className={styles.logo}
        href="mailto:paulreitzphoto%40aol.com?Subject=Your%20website"
        target="_self"
      >
        <img
          src={require("./img/logo_grey.png")}
          alt="Paul Reitz Photography"
        />
      </a>
    </div>
  );
}
